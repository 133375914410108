<template>
  <div>
    <a-row>
      <a-col :span="6">
        <a-descriptions :column="1" class="simiot-descriptions">
          <a-descriptions-item label="入库批次">
            {{ batchOperationRecordInfo.operation_no }}
          </a-descriptions-item>
          <a-descriptions-item label="入库时间">
            {{ batchOperationRecordInfo.created_at }}
          </a-descriptions-item>
          <a-descriptions-item label="入库人">
            {{ batchOperationRecordInfo.operator_name }}
          </a-descriptions-item>
          <a-descriptions-item label="入库总数">
            {{ batchOperationRecordInfo.operation_count }}
          </a-descriptions-item>
          <a-descriptions-item label="入库成功数">
            {{ batchOperationRecordInfo.success_count }}
          </a-descriptions-item>
          <a-descriptions-item label="入库失败数">
            {{ batchOperationRecordInfo.failure_count }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="6">
        <a-descriptions :column="1" class="simiot-descriptions simiot-descriptions-max-6">
          <a-descriptions-item label="运营商类型">
            {{ batchOperationRecordInfo.carrier_type }}
          </a-descriptions-item>
          <a-descriptions-item label="运营商账户">
            {{ batchOperationRecordInfo.carrier_account_name }}
          </a-descriptions-item>
          <a-descriptions-item label="运营商套餐">
            {{ batchOperationRecordInfo.carrier_product_name }}
          </a-descriptions-item>
          <a-descriptions-item label="VPDN分组ID">
            {{ batchOperationRecordInfo.vpdn_id }}
          </a-descriptions-item>
          <a-descriptions-item label="服务周期">
            {{ batchOperationRecordInfo.service_cycle }}
          </a-descriptions-item>
          <a-descriptions-item label="APN参数">
            {{ batchOperationRecordInfo.apn_parameters }}
          </a-descriptions-item>
          <a-descriptions-item label="其他服务">
            {{ batchOperationRecordInfo.other_services }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="6">
        <a-descriptions :column="1" class="simiot-descriptions simiot-descriptions-max-6">
          <a-descriptions-item label="开卡日期">
            {{ batchOperationRecordInfo.open_card_date }}
          </a-descriptions-item>
          <a-descriptions-item label="运营商沉默期">
            {{ batchOperationRecordInfo.carrier_silent_period_end_date }}
          </a-descriptions-item>
          <a-descriptions-item label="运营商测试期">
            {{ batchOperationRecordInfo.carrier_test_period_end_date }}
          </a-descriptions-item>
          <a-descriptions-item label="测试流量">
            {{ batchOperationRecordInfo.test_traffic }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="6">
        <a-descriptions :column="1" class="simiot-descriptions simiot-descriptions-max-7">
          <a-descriptions-item label="卡片材质">
            {{ batchOperationRecordInfo.card_type }}
          </a-descriptions-item>
          <a-descriptions-item label="卡片尺寸">
            {{ batchOperationRecordInfo.card_shape }}
          </a-descriptions-item>
          <a-descriptions-item label="卡板价格(元/张)">
            {{ batchOperationRecordInfo.carrier_card_price }}
          </a-descriptions-item>
          <a-descriptions-item label="卡板费用(元)">
            {{ batchOperationRecordInfo.carrier_total_card_fee }}
          </a-descriptions-item>
          <a-descriptions-item label="重跑次数">
            {{ batchOperationRecordInfo.run_times }}
          </a-descriptions-item>
          <a-descriptions-item label="备注">
            <span class="batch-operation-record-info">
              {{ batchOperationRecordInfo.remark }}
            </span>
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>

    <a-row>
      <a-col>
        <span>
          注：执行批量卡号入库操作时，如果有一张卡入库失败，不会影响其他卡的入库，但本次入库的操作结果为失败。
        </span>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'BatchOperationRecordSimCardsImportInfo',
  props: {
    batchOperationRecordInfo: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.batch-operation-record-info {
  word-break: break-all;
}
</style>
